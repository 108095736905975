import FileMetaDTO from "@/dto/files/FileMetaDTO";

export default class SignableDocumentDTO {

    public id: number | null = null;

    public primaryDocument: FileMetaDTO | null = null;

    public signedDocument: FileMetaDTO | null = null;

    public documentType: string | null = null;

    public documentSubType: string | null = null;

    public externalId: string | null = null;

    public personId: number | null = null;

    public companyId: number | null = null;

    public status = "CREATED";

    public customerName = "";

    public createDate: Date | null = null;

    public signDate: Date | null = null;

}