



















































import {Component, Vue} from "vue-property-decorator";
import FileService from "@/services/FileService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {namespace} from "vuex-class";
import SignableDocumentDTO from "@/dto/files/SignableDocumentDTO";
import SignableDocumentModal from "@/components/archive/SignableDocumentModal.vue";
import RouteNames from "@/router/RouteNames";
import Workspace, {WorkspaceType} from "@/dto/auth/Workspace";
import UserDTO from "@/dto/UserDTO";
import DocumentService from "@/services/DocumentService";
import PersonService from "@/services/PersonService";
import PersonDTO from "@/dto/person/PersonDTO";
import SignableDocumentFilter from "@/dto/archive/SignableDocumentFilter";
import BusinessTaxReturnDTO from "@/dto/taxation/business/BusinessTaxReturnDTO";
import DocumentActionModal from "@/components/util/DocumentActionModal.vue";
import DeleteConfirmationModal from "@/components/util/DeleteConfirmationModal.vue";
import Workspaces from "@/state/Workspaces";

const PERSON = 'p';
const COMPANY = 'c';

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component
export default class AuthForms extends Vue {

    private successful = true;
    private message = "";

    private signableDocuments: Array<SignableDocumentDTO> = [];

    @AppModule.State
    private isMobile!: boolean;

    @AppModule.State
    private loading!: boolean;

    @AppModule.Action
    private startLoading!: () => void

    @AppModule.Action
    private stopLoading!: () => void

    private person: PersonDTO | null = null;

    public pageNumber = 1;

    private totalPages = 1;


    mounted() {
      this.loadDocuments().then(() => {
        if(Workspaces.isCurrentWorkspacePerson){
          this.loadPerson();
        }
      })
    }

    loadPerson() {
        this.startLoading();
        this.message = "";
        return PersonService.getById(Workspaces.getCurrent?.id as number).then(
            response => {
                this.person = response.data;
                this.successful = true;
                this.stopLoading();
            },
            error => {
                this.successful = false;
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
                this.stopLoading();
            }
        );
    }

    loadDocuments() {
        this.message = "";
        this.startLoading();
        const filter = new SignableDocumentFilter();
        filter.customerType = Workspaces.getCurrent?.type.toString() as string;
        filter.customerId = Workspaces.getCurrent?.id as number;
        filter.documentType = "AUTH_FORM";
        filter.page = this.pageNumber;
        return DocumentService.loadSignableDocuments(filter).then(
            response => {
                this.signableDocuments = response.data.data;
                this.totalPages = response.data.countOfPages;
                this.successful = true;
                this.stopLoading();
            },
            error => {
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
                this.successful = false;
                this.stopLoading();
            }
        );
    }

    redirect(){
      this.$router.push({name: RouteNames.ARCHIVE_SIGNABLE_LIST_NEW, params: this.$route.params})
    }

  get isPerson(){
    return this.$wss.getCurrentType == WorkspaceType.PERSON;
  }

    addSignableFile() {
        const newFile = new SignableDocumentDTO();
        newFile.documentType = "AUTH_FORM";
        this.$modal.show(
            SignableDocumentModal,
            {
                file: newFile,
                onSave: () => {
                    this.saveSignableDocument(newFile).then(() => this.loadDocuments());
                }
            },
            {
				width: "50%",
				height: "auto",
				style: {
					overflow: "visible"
				}
			}
        );
    }

    saveSignableDocument(fileDto: SignableDocumentDTO) {
        if (Workspaces.getCurrent?.type == WorkspaceType.COMPANY) {
            fileDto.companyId = Workspaces.getCurrent.id;
        } else if (Workspaces.getCurrent?.type == WorkspaceType.PERSON) {
            fileDto.personId = Workspaces.getCurrent.id;
        } else {
            return Promise.reject();
        }
        this.startLoading();
        return DocumentService.sendAuthForm(fileDto).then(
            response => {
                this.successful = true;
                this.message = this.$t("common.saved") as string;
                this.stopLoading();
            },
            error => {
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
                this.successful = false;
                this.stopLoading();
            }
        );
    }

    refreshDocument(document: SignableDocumentDTO) {
        this.startLoading();
        this.message = "";
        DocumentService.refreshSignableDocument(document).then(
            response => {
                document.id = response.data.id;
                document.primaryDocument = response.data.primaryDocument;
                document.signedDocument = response.data.signedDocument;
                document.documentType = response.data.documentType;
                document.externalId = response.data.externalId;
                document.personId = response.data.personId;
                document.companyId = response.data.companyId;
                document.status = response.data.status;
                this.successful = true;
                this.stopLoading();
            },
            error => {
                this.stopLoading();
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
                this.successful = false;
            }
        );
    }

    get isSpousePresent() {
        return this.person && this.person.maritalStatus == 'MARRIED' && this.person.spouse && this.person.spouse.email;
    }

    changePage(page: number) {
        this.pageNumber = page;
        this.loadDocuments();
    }

    showDeleteConfirmationModal(document: SignableDocumentDTO) {
        this.$modal.show(
            DeleteConfirmationModal,
            {
                onDelete: () => this.deleteAuthForm(document.id as number),
            },
            {width: "50%", height: "auto"}
        );
    }

    private deleteAuthForm(id: number) {
        this.startLoading();
        this.message = "";
        DocumentService.deleteSignableDocument(id).then(
            res => this.loadDocuments(),
            error => {
                this.stopLoading();
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
                this.successful = false;
            }
        )
    }


}
